import React from "react"
import styled from "styled-components"

const StyledCard = styled.div`
	display: flex;
	align-items: center;
	/* justify-content: space-around; */
	padding: 3rem 2rem;
	border-radius: 32px;
	background: #ffffff;
	/* box-shadow: 20px 20px 60px #cfcfcf, -20px -20px 60px #ffffff; */
	box-shadow: 0px 24px 48px 0 #00323816;
	width: 100%;

	& .img-container {
		margin-right: 36px;
	}

	@media only screen and (max-width: 768px) {
		flex-direction: column;

		& .img-container {
			margin-right: 0;
			margin-bottom: 16px;
			order: -1;
		}
	}
`
const EducationCard = ({ img, content }) => {
	return (
		// <StyledCard className="roll-in-bottom">
		<StyledCard
			// data-sal="flip-up"
			data-sal-duration="600" // changes duration of the animation (from 200 to 2000 ms)
			// data-sal-delay="300" // adds delay to the animation (from 5 to 1000 ms)
			data-sal-easing="ease">
			<div className="img-container">{img}</div>
			<div>
				<div>{content}</div>
			</div>
		</StyledCard>
	)
}

export { EducationCard }
