import React from "react"
import { Box, Heading, Text } from "../gazebo"
import { TempContainer } from "../components/TempContainer"
import { StaticImage } from "gatsby-plugin-image"

const Now = () => {
	return (
		<Box style={{ width: "100%", position: "relative" }} mt={4}>
			<StaticImage
				src="../images/now-bg.svg"
				quality={95}
				formats={["AUTO", "WEBP", "AVIF"]}
				alt=""
				placeholder="blurred"
				style={{ width: "100%", height: "100%", position: "absolute" }}
			/>
			<TempContainer
				style={{ position: "relative", zIndex: 10, display: "flex", flexDirection: "column", alignItems: "center", padding: "48px 0" }}>
				<Heading as="h2" fontSize={700} mb={2} style={{ textAlign: "center", color: "#FFF2F8" }}>
					What I'm Doing Now (April 2021)
				</Heading>
				<Text fontSize={400} style={{ textAlign: "center", color: "#FFF2F8" }}>
					Currently, I am in my final semester of graduate school. I am earning my Master of Social Work degree from Boston University.
				</Text>
			</TempContainer>
		</Box>
	)
}

export { Now }
