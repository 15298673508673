import React from "react"
import { EducationCard } from "../components/EducationCard"
import { StaticImage } from "gatsby-plugin-image"
import { Heading, Text } from "../gazebo"
const EducationalBackground = () => {
	return (
		<ul style={{ listStyle: "none", display: "flex", flexDirection: "column", width: "100%", maxWidth: "none" }}>
			<li style={{ maxWidth: "none", marginBottom: "48px" }}>
				<EducationCard
					img={
						<StaticImage
							src="../images/gordon-blue.png"
							width={300}
							quality={100}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="Gordon College logo"
							placeholder="blurred"
						/>
					}
					content={<BachelorsContent />}
				/>
			</li>
			<li style={{ maxWidth: "100%", marginBottom: "48px" }}>
				<EducationCard
					img={
						<StaticImage
							src="../images/bu-logo.png"
							width={300}
							quality={100}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="Boston University logo"
							placeholder="blurred"
						/>
					}
					content={<MastersContent />}
				/>
			</li>
		</ul>
	)
}

const BachelorsContent = () => {
	return (
		<>
			<Text style={{ color: "#CD3C80", fontWeight: 700 }}>2012 - 2016</Text>
			<Heading as="h4" fontSize={400} mb={1}>
				Bachelor of Arts in Social Work &amp; Sociology
			</Heading>
			<Text>
				While earning my degree, I participated in numerous social service opportunities, such as volunteering at the Boys & Girls Club and
				becoming an active member of the Homeless Ministry. I was also a Teaching Assistant for a statistics course.
			</Text>
		</>
	)
}

const MastersContent = () => {
	return (
		<>
			<Text style={{ color: "#CD3C80", fontWeight: 700 }}>2019 - 2021</Text>
			<Heading as="h4" fontSize={400} mb={1}>
				Masters in Social Work
			</Heading>
			<Text>
				I began graduate school in the fall of 2019 at the Boston University School of Social Work in the Advanced Standing program. My
				anticipated date of program completion is July 2021.
			</Text>
		</>
	)
}

export { EducationalBackground }
