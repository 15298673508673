import React from "react"
import styled from "styled-components"

const StyledCard = styled.div`
	display: flex;
	flex-direction: column;
	padding: 3rem 2rem;
	width: 300px;

	.icon {
		height: 160px;
		width: 160px;
		background-color: #6778cb;
		border-radius: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 32px;
		box-shadow: 0 20px 40px 0 rgba(0, 0, 67, 0.1);

		& img {
			height: 90px;
			width: 90px;
		}
	}
`
const TempCard = ({ icon, title, content }) => {
	return (
		<StyledCard>
			<div className="icon">{icon}</div>
			<div>{title}</div>
			<div>{content}</div>
		</StyledCard>
	)
}

export { TempCard }
