import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Heading, Text } from "../gazebo"
import { Link } from "gatsby"
import { TempButton } from "../components/TempButton"
import styled from "styled-components"
import { TempContainer } from "./TempContainer"

const StyledContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1.6rem;
	align-items: center;
	padding: 4.8rem 0;
	& > * {
		flex-basis: 100%;

		@media (min-width: 800px) {
			flex-basis: calc(50% - 1.6rem);
		}
	}

	.img-container {
		margin-bottom: 1.45rem;
		order: -1;
		display: flex;
		justify-content: center;

		@media (min-width: 800px) {
			order: 1;
		}
	}
`
const Intro = () => {
	return (
		<TempContainer>
			<StyledContent>
				<div className="copy">
					<Heading as="h1" fontSize={800} style={{ lineHeight: 1.2 }} mt={4} mb={1}>
						Hi,
						<br />
						I'm Becca!
					</Heading>
					<Text mb={1}>
						I am a licensed social worker who believes in leaning into strengths and the power of hope. I have experience working with
						children and families in a variety of settings, including in early childhood education and community mental health.
					</Text>
					<Text mb={3}>
						I have specific interests in medical and behavioral health care, social determinants of health, and child welfare policy. Other
						interests include running, reading, and learning new things.
					</Text>
					<Link to="/about">
						<TempButton>Learn More</TempButton>
					</Link>
				</div>
				<div className="img-container">
					<StaticImage
						src="../images/becca-bg.jpg"
						// width={600}
						layout="constrained"
						quality={100}
						formats={["AUTO", "WEBP", "AVIF"]}
						alt="headshot of Becca"
						placeholder="blurred"
						style={{ borderRadius: "16px" }}
					/>
				</div>
			</StyledContent>
		</TempContainer>
	)
}

export { Intro }
