import * as React from "react"
import { Box, Heading, Text } from "../gazebo"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { TempButton } from "../components/TempButton"
import { Link } from "gatsby"
import { Intro } from "../components/Intro"
import { TempContainer } from "../components/TempContainer"
import { Now } from "../components/Now"
import { AreasOfFocus } from "../components/AreasOfFocus"
import { EducationalBackground } from "../components/EducationalBackground"
import { SelectedWork } from "../components/SelectedWork"

const IndexPage = () => (
	<>
		<StaticImage
			src="../images/blob.svg"
			// width={600}
			layout="constrained"
			quality={100}
			formats={["AUTO", "WEBP", "AVIF"]}
			alt=""
			placeholder="blurred"
			style={{ position: "absolute", right: 0, zIndex: -1 }}
		/>
		<Layout>
			<SEO title="Home" />
			{/* <Hero /> */}
			<Intro />
			<AreasOfFocus />
			<Now />
			<Box style={{ width: "100%" }} pt={4} pb={4} mt={4}>
				<TempContainer>
					<Heading as="h2" mb={4}>
						Educational Background
					</Heading>
					<EducationalBackground />
				</TempContainer>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Link to="/education">
						<TempButton>See More</TempButton>
					</Link>
				</div>
			</Box>
			<SelectedWork />
			{/* <Box style={{ width: "100%", height: "800px" }} pt={4} pb={4} mt={4}>
			<InterventionBinderExample />
		</Box> */}
		</Layout>
	</>
)

export default IndexPage
