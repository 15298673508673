import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { Box, Heading, Text } from "../gazebo"
import { TempButton } from "../components/TempButton"
import { Link } from "gatsby"
import { TempContainer } from "../components/TempContainer"

const StyledContainer = styled.div`
	padding: 8rem 6rem;
	margin-bottom: 4rem;
	background-color: #456cc6;
	border-radius: 16px;
	display: flex;
	justify-content: space-around;
	align-items: center;

	& p {
		margin-right: 16px;
	}

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		& .gatsby-image-wrapper {
			order: -1;
			margin-bottom: 24px;
		}
		& p {
			margin-right: 0;
		}
	}
`

const SelectedWork = () => {
	return (
		<Box style={{ width: "100%", backgroundColor: "#2C3E91" }} pt={4} pb={4} mt={4}>
			<TempContainer>
				<Text style={{ textAlign: "center", maxWidth: "none", color: "#ffffff" }}>Featured Work</Text>
				<Heading as="h3" style={{ textAlign: "center", color: "#ffffff" }} mb={2}>
					Intervention List
				</Heading>
				<StyledContainer>
					<Text style={{ color: "#ffffff" }}>
						During my Masters-level internship as an in-home therapist, part of my role was to develop and implement client-centered
						clinical interventions that aligned with my client's treatment goals. Since the majority of this internship was completed
						virtually, it was essential to also develop interventions that could be implemented in person and over telehealth. The
						intervention list that I created can be found{" "}
						<a
							href="https://becca-fipphen-content.s3.amazonaws.com/becca-fipphen-lsw-intervention-list.pdf"
							style={{ textDecoration: "underline", color: "#EEBED5", fontWeight: 700 }}>
							here
						</a>
						.
					</Text>
					<StaticImage
						src="../images/icons/notes.svg"
						width={250}
						layout="constrained"
						quality={100}
						formats={["AUTO", "WEBP", "AVIF"]}
						alt="notes"
						placeholder="blurred"
					/>
				</StyledContainer>
			</TempContainer>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Link to="/work">
					<TempButton>See More</TempButton>
				</Link>
			</div>
		</Box>
	)
}

export { SelectedWork }
