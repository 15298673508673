import React from "react"
import { Box, Heading, Text } from "../gazebo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBaby, faDumbbell, faHandHoldingHeart, faHandHoldingMedical, faHouseDamage } from "@fortawesome/free-solid-svg-icons"
import { TempContainer } from "../components/TempContainer"
import { TempCard } from "./TempCard"
import styled from "styled-components"
import family from "../images/icons/041-reputation.svg"
import growth from "../images/icons/025-development.svg"
import traumaHeart from "../images/icons/012-heart.svg"
import eye from "../images/icons/018-visualize.svg"
import scale from "../images/icons/028-morality.svg"
import social from "../images/icons/036-soft-skills.svg"
const StyledListContainer = styled.div`
	--space: 4rem;
	& ul {
		list-style: none;
		max-width: 100%;
		margin: 0;
	}

	& > * {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		margin: calc(var(--space) / 2 * -1);
	}

	& > * > * {
		margin: calc(var(--space) / 2);
	}
`
const AreasOfFocus = () => {
	return (
		<Box style={{ width: "100%" }} pt={4} pb={4}>
			<TempContainer>
				<Heading as="h2" fontSize={700} mb={2}>
					Guiding Principles
				</Heading>
				<Text mb={2}>As a social worker, I believe that...</Text>
				<StyledListContainer>
					<ul>
						<li>
							<TempCard
								icon={<img src={family} alt="family" />}
								title={
									<Heading as="h4" fontSize={400} mb={1}>
										Children &amp; Families
									</Heading>
								}
								content={
									<Text>
										Whether it's providing case management or therapeutic services, I believe that every child and family deserves a
										provider that listens, cares, and advocates for their needs.
									</Text>
								}
							/>
						</li>
						<li>
							<TempCard
								icon={<img src={growth} alt="growth" />}
								title={
									<Heading as="h4" fontSize={400} mb={1}>
										Strengths-Based Care
									</Heading>
								}
								content={
									<Text>
										I believe that every individual and family has their own unique strengths that can be used to meet their goals. I
										support all clients in identifying their strengths and determining how they can be used in treatment or to meet goals.
									</Text>
								}
							/>
						</li>
						<li>
							<TempCard
								icon={<img src={traumaHeart} alt="bandaged heart" />}
								title={
									<Heading as="h4" fontSize={400} mb={1}>
										Trauma-Informed
									</Heading>
								}
								content={
									<Text>
										Trauma can have a profound impact on individuals, families, and communities. I actively strive to use a trauma-informed
										lens with clients that focuses on building safety, client empowerment, recognizing symptoms of trauma, and preventing
										re-traumatization.
									</Text>
								}
							/>
						</li>
						<li>
							<TempCard
								icon={<img src={eye} alt="eye" />}
								title={
									<Heading as="h4" fontSize={400} mb={1}>
										Cultural Humility
									</Heading>
								}
								content={<Text>All parts of a client's culture and identity should be respected, valued, and celebrated.</Text>}
							/>
						</li>
						<li>
							<TempCard
								icon={<img src={social} alt="social" />}
								title={
									<Heading as="h4" fontSize={400} mb={1}>
										Social Determinants
									</Heading>
								}
								content={
									<Text>
										Basic human needs such as housing, food/nutrition, employment/ financial benefits, and transportation play crucial roles
										in the health and well-being of individuals and their families, as well as communities. I believe in routinely assessing
										for social determinants of health needs and supporting clients in accessing resources that meet their needs.
									</Text>
								}
							/>
						</li>
						<li>
							<TempCard
								icon={<img src={scale} alt="justice scale" />}
								title={
									<Heading as="h4" fontSize={400} mb={1}>
										Policy &amp; Advocacy
									</Heading>
								}
								content={
									<Text>
										Policies and legislation directly affect the well-being of individuals, families, and communities. I strive to be
										actively involved in advocating for social justice and human rights at the macro level.
									</Text>
								}
							/>
						</li>
					</ul>
				</StyledListContainer>
			</TempContainer>
		</Box>
	)
}

export { AreasOfFocus }
